import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "preview" ]

  fileSelected(event) {
    var input = event.target
    var output = this.previewTarget

    if (input.files && input.files[0]) {
      var reader = new FileReader()
        reader.onload = function () {
         output.src = reader.result
       }
       reader.readAsDataURL(input.files[0])
    }
  }

  fileSelect(event) {
    const input = event.target

    Array.from(input.files).forEach(file => this.uploadFile(file))

    // target.insertAdjacentHTML("beforebegin", `
//         <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
//           <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
//           <span class="direct-upload__filename"></span>
//         </div>
//       `)
//     target.previousElementSibling.querySelector(`.direct-upload__filename`).textContent = file.name
  }

  uploadFile(file) {
    console.log(file)
  }
}
