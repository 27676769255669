import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "echaiSliderScrollArea", "echaiSlides", "slide", "previousArrow", "nextArrow" ]
  static values = { index: Number }

  initialize() {
    this.playerEl = document.body.querySelector('amp-story-player')
    this.playerEl.addEventListener('amp-story-player-close', this.closePlayer.bind(this))

    this.lightboxEl = document.body.querySelector('.lightbox')

    const containerPadding =
      parseFloat(getComputedStyle(this.echaiSlidesTarget).paddingLeft) +
      parseFloat(getComputedStyle(this.echaiSlidesTarget).paddingRight)

    const slideMargin = parseFloat(getComputedStyle(this.slideTarget).marginRight)
    this.slideWidth = slideMargin + this.slideTarget.offsetWidth

    this.maxScroll =
      this.echaiSliderScrollAreaTarget.offsetWidth +
      slideMargin -
      containerPadding -
      this.slideTargets.length * this.slideWidth;

    if (this.maxScroll > 0) {
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
      this.nextArrowTarget.classList.toggle('visible', !isMobile)

      this.element.classList.toggle('overflow-right', true)
    }
  }

  next() {
    this.indexValue = Math.max(this.maxScroll, this.indexValue - this.slideWidth * 2)
  }

  previous() {
    this.indexValue = Math.min(0, this.indexValue + this.slideWidth * 2)
  }

  play({ params: { url } }) {
    this.lightboxEl.classList.remove('closed')
    document.body.classList.toggle('lightbox-open')

    this.playerEl.show(url, null, { animate: false })
    this.playerEl.play()
  }

  closePlayer() {
    this.playerEl.pause();
    document.body.classList.toggle('lightbox-open', false)
    this.lightboxEl.classList.add('closed')
    this.slidesTarget.forEach((slide) => {
      slide.classList.remove('hidden')
    })
  }

  indexValueChanged() {
    this.scrollSlider()

    this.element.classList.toggle('overflow-left', this.indexValue < 0)
    this.element.classList.toggle('overflow-right', this.indexValue > this.maxScroll)

    this.previousArrowTarget.classList.toggle('visible', this.indexValue < 0)
    this.nextArrowTarget.classList.toggle('visible', this.indexValue > this.maxScroll)
  }

  scrollSlider() {
    this.slideTargets.forEach((slide) => {
      slide.style['transform'] = `translateX(${this.indexValue}px)`;
    });
  }
}
